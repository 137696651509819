import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql, withPrefix, navigate } from "gatsby"
import isLoggedIn from "../../services/auth";
import LoadingIndicator from '../../components/elements/LoadingSpinner';

import { Router, Route } from "@reach/router"

import Login from './pages/Login';
import Billing from './pages/Billing';
import Shipping from "./pages/Shipping";
import Payment from "./pages/Payment";
import { useSelector } from "react-redux";
import Waiting from "./pages/Waiting";
import Notification from "../../components/elements/Notification";
import Success from "./pages/Success";
import Error from "./pages/Error";

const Checkout = (props) => {

    const currentStep = useSelector(state => state?.checkout.currentStep);

    const [loggedIn, setLoggedIn] = useState();
    const [loading, setLoading] = useState(true);

    console.log("PROPS CHECKOUT", props)

    useEffect(() => {

        if(props.params['*'].includes('success') || props.params['*'].includes('error') || props.params['*'].includes('waiting')){
            setLoading(false);
            return false;
        }
        
        isLoggedIn().then((res) => {

            setLoggedIn(res);
            setLoading(false);
            if (currentStep == null) {
                if (res) {
                    navigate('/kasse/billing')
                } else {
                    navigate('/kasse/login')
                }
            } else {
                if (currentStep !== 'login' && res) {
                    navigate('/kasse/'+currentStep)
                } else {
                    navigate('/kasse/login')
                }
            }

            

        }).catch(err => {
            navigate('/kasse/login')
        })


    }, [])

    return (
        <div className="">
            <Router basepath={"/kasse"}>
                <Login path="/login" />
                <Billing path="/billing" />
                <Shipping path="/shipping" />
                <Payment path="/payment" />
                <Waiting path="/waiting/:orderId/:email" />
                <Success path="/success/:orderId/:email" />
                <Error path="/error" />
            </Router>
            <LoadingIndicator type="page" active={loading} />
            <Notification />
        </div>
    )
}

export default Checkout;
