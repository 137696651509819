import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import axios from "axios";
import parse from 'html-react-parser';
import { API_BASE, STRIPE_KEY } from "../../../spectory-config";
import Layout from "../../../components/layout"
import Button from "../../../components/elements/Button"
import LoadingSpinner from "../../../components/elements/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { changeCheckoutStep, clearCart } from "../../../store/actions";
import * as sidecarStyles from '../../../components/elements/SideCart/styles.module.scss'
import * as cartStyles from "../../warenkorb/styles.module.scss"
import * as styles from "./styles.module.scss"
import ThirdContainerSidebar from "../../../components/elements/ThirdContainerSidebar";
import { floatToString } from "../../../functions/money";
import getProductImageUrl from "../../../functions/image";

const Success = (props) => {

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const [is404, setIs404] = useState(null);
    const [taxes, setTaxes] = useState(0.0);

    const dispatch = useDispatch();
    dispatch(changeCheckoutStep({ currentStep: 'login' }))

    dispatch(clearCart());

    const loadData = () => {

        if (props.email == undefined || props.orderId == undefined) {
            setIs404(true)
            return false;
        }

        var config = {
            method: 'POST',
            url: `${API_BASE}/checkout/order`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                email: decodeURIComponent(props.email),
                order_id: props.orderId
            }
        };

        console.log("DATA 1", config);


        axios(config)
            .then(function (response) {

                console.log("response DATA", response)
                console.log("response DATA 2", response.data)

                let couponCodesLoaded = false;

                response.data.data.items.forEach(item => {
                    if (item.coupon_code !== '') {
                        couponCodesLoaded = true;
                    }
                })


                if (couponCodesLoaded) {

                    if (response.data.c === 200) {
                        setIs404(false);
                        setOrder(response.data.data);
                        console.log("DATA", response.data.data)

                        let _taxes = 0.0;

                        Object.keys(response.data.data.payment.tax).forEach((taxId) => {
                            _taxes = _taxes + parseFloat(response.data.data.payment.tax[taxId].amount)
                        })

                        setTaxes(_taxes);

                    } else {
                        setIs404(true);
                    }

                    setLoading(false);
                } else {

                    setTimeout(() => loadData(), 5000);

                }

            })
            .catch(function (error) {

                console.log("response err", error)

                setLoading(false);
            });



    }

    useEffect(() => {

        loadData();

    }, [])

    const sideBarContent = <>

        <h1 style={{ marginTop: 30 }}>Guten Tag,</h1>

        <p>
            vielen Dank für Ihre Bestellung. Sie werden in kürze eine E-Mail mit Ihrer Bestellbestätigung erhalten.
            <br />
            <br />
            Wir werden Sie benachrichtigen, sobald Ihr(e) Artikel versandt wurde(n).
        </p>

        <Button
            title={"Bestellübersicht herunterladen"}
            type="secondary"
            action={() => alert("")}
            additionalStyle={{
                width: '80%',
                marginTop: 50,
                paddingLeft: 0,
                paddingRight: 0
            }}

        />
        <Button
            title={"Zurück zur Startseite"}
            type="primary"
            destination={'/'}
            additionalStyle={{
                width: '80%',
                marginTop: 30
            }}
        />

    </>

    return (
        <Layout path={props.path}>

            <ThirdContainerSidebar
                mobileReverse={true}
                sideBarContent={is404 === false ? sideBarContent : <></>}
            >
                {
                    is404 === false && order !== null &&
                    <>
                        <div className="container fluid" style={{ marginTop: 30 }}>
                            <div className="col8">
                                <div>
                                    {/* Voraussichtliches Lieferdatum: */}
                                </div>
                            </div>
                            <div className="col4">
                                <div>
                                    <span className="grey">Die Bestellung geht an:</span><br />
                                    {
                                        parse(order.shiping_data || 'Abholung im Laden')
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="container fluid" style={{ marginTop: 50 }}>
                            <div className="col12">
                                <div>
                                    <h4>Ihre Bestellung</h4>
                                    <div
                                        className={`${cartStyles.container}`}
                                    >
                                        <div className={`${cartStyles.item} ${cartStyles.header}`}>
                                            <div
                                                className={`${cartStyles.imageContainer}  ${styles.imageContainer}`}
                                            >
                                                <div className={cartStyles.label}>Produkt</div>
                                            </div>
                                            <div
                                                className={`${cartStyles.contentContainer} ${styles.contentContainer}`}
                                            ></div>
                                            <div
                                                className={`${cartStyles.priceContainer} ${styles.priceContainer}`}
                                            >
                                                <div className={cartStyles.label}>Preis</div>
                                            </div>
                                            <div
                                                className={`${cartStyles.quantityContainer} ${styles.quantityContainer}`}
                                            >
                                                <div className={cartStyles.label}>Menge</div>
                                            </div>
                                            <div
                                                className={`${cartStyles.sumContainer} ${styles.sumContainer}`}
                                            >
                                                <div className={cartStyles.label}>Gesamt</div>
                                            </div>
                                        </div>
                                        {
                                            order.items !== undefined && order.items.map(item => {

                                                return <div
                                                    className={cartStyles.item}
                                                >
                                                    <div
                                                        className={`${cartStyles.imageContainer}  ${styles.imageContainer}`}
                                                    >
                                                        <div
                                                            className={`${cartStyles.image} ${styles.image}`}
                                                            style={{ backgroundImage: `url(${getProductImageUrl(item.sku, '260x260')})` }}>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`${cartStyles.contentContainer} ${styles.contentContainer}`}
                                                    >
                                                        <h4
                                                            className={`${cartStyles.title}`}
                                                            style={{ fontSize: 16 }}
                                                        >
                                                            {item.title}
                                                        </h4>
                                                        {
                                                            item.coupon_url !== undefined && <a target="_blank" href={item.coupon_url}>Gutschein herunterladen</a>
                                                        }
                                                        <div>{item.detail}</div>
                                                    </div>
                                                    <div
                                                        className={`${cartStyles.priceContainer} ${styles.priceContainer}`}
                                                    >
                                                        € {floatToString(parseFloat(item.coupon_url !== undefined ? item.total : item.single))}
                                                    </div>
                                                    <div
                                                        className={`${cartStyles.quantityContainer} ${styles.quantityContainer}`}
                                                    >
                                                        {item.quantity}
                                                    </div>
                                                    <div
                                                        className={`${cartStyles.sumContainer} ${styles.sumContainer}`}
                                                    >
                                                        € {floatToString(parseFloat(item.total))}
                                                    </div>

                                                </div>
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container fluid" style={{ marginTop: 50 }}>
                            <div className="col6"></div>
                            <div className="col6">
                                <div>
                                    <table className={`${cartStyles.table} ${styles.table}`}>
                                        <tr>
                                            <td style={{paddingBottom: 5}}>Zwischensumme</td>
                                            <td style={{paddingBottom: 5}}>€ {floatToString(parseFloat(order.payment.subtotal))}</td>
                                        </tr>
                                        <tr>
                                            <td style={{color: '#6E6E6E'}}>davon Mehrwertsteuer</td>
                                            <td style={{color: '#6E6E6E'}}>€ {floatToString(order.payment.subtotal_tax)}</td>
                                        </tr>
                                        <tr>
                                            <td>Versand</td>
                                            <td>€ {floatToString(parseFloat(order.payment.shipping))}</td>
                                        </tr>

                                        {order.payment.credit > 0 && 
                                            <tr>
                                                <td>Guthaben</td>
                                                <td>-€ {floatToString(parseFloat(order.payment.credit))}</td>
                                            </tr>
                                        }

                                        {order.payment.coupon > 0 && 
                                            <tr>
                                                <td>Gutschein</td>
                                                <td>-€ {floatToString(parseFloat(order.payment.coupon))}</td>
                                            </tr>
                                        }

                                        <tr style={{ textTransform: 'uppercase' }}>
                                            <td><strong>Gesamtsumme</strong></td>
                                            <td><strong>€ {floatToString(parseFloat(order.payment.total))}</strong><br/><small style={{textTransform: 'none'}}> (inkl. €{floatToString(parseFloat(taxes))} MwSt.)</small></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {is404 === true &&
                    <div style={{ textAlign: 'center' }}>
                        <h1>Bestellung nicht gefunden</h1>
                        <p>
                            Wir haben diese Bestellung leider nicht gefunden.
                        </p>
                        <Button
                            title={"Zurück zur Startseite"}
                            type="primary"
                            destination={'/'}
                            additionalStyle={{
                                marginTop: 30
                            }}
                        />
                    </div>
                }



            </ThirdContainerSidebar>
            <LoadingSpinner active={loading} type="wholeContainerTransparent" label="Bestellung wird geladen" />

        </Layout>
    )
}

export default Success;
