import { Link, navigate, withPrefix } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import isLoggedIn, { getUserToken } from "../../../services/auth"
import axios from "axios";
import { API_BASE } from "../../../spectory-config";
import Layout from "../../../components/layout"
import Button from "../../../components/elements/Button"
import TextInput from "../../../components/elements/Inputs/TextInput";
import Checkbox from "../../../components/elements/Inputs/Checkbox";
import LoadingSpinner from "../../../components/elements/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { changeCheckoutStep } from "../../../store/actions";


const Error = (props) => {

    return (
        <Layout path={props.path}>
            <div className="container" style={{ textAlign: 'center', marginTop: 100, marginBottom: 100, minHeight: 'calc(100vh - 600px)' }}>
                <div className="col12">
                    <div>
                        <h4>Bestellung fehlgeschlagen</h4>
                        <strong>Ihre Bestellung ist leider fehlgeschlagen. Bitte versuchen Sie es erneut.</strong>
                        <Button
                            title={"Zurück zur Startseite"}
                            type="primary"
                            destination={'/'}
                            additionalStyle={{
                                marginTop: 30
                            }}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Error;
